<template>
    <el-card class="box-card">
        <!-- 表单只有一个文本框的时候，按回车会自动submit表单，需要禁用sunmit -->
        <el-form
            ref="searchform"
            :inline="true"
            :model="form"
            @submit.native.prevent
            size="medium"
        >
            <el-form-item label="角色名" prop="name">
                <el-input
                    v-model="form.name"
                    placeholder="角色名搜索"
                    @keyup.enter.native="search()"
                    clearable
                    class="name_c"
                ></el-input>
            </el-form-item>
            <el-form-item  label="性别" prop="gender">
                <el-select
                    v-model="form.gender"
                    filterable
                    clearable
                    placeholder=""
                    class="gender_c"
                >
                    <el-option
                        v-for="item in AllGender"
                        :key="item.name"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item  label="系列" prop="group">
                <el-select
                    v-model="form.group"
                    filterable
                    clearable
                    placeholder="选择系列"
                    class="group_c"
                >
                    <el-option
                        v-for="item in AllGroup"
                        :key="item.name"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item  label="属性" prop="hide">
                <el-select
                    v-model="form.hide"
                    filterable
                    clearable
                    placeholder="选择属性"
                    class="hide_c"
                >
                    <el-option
                        v-for="item in AllHide"
                        :key="item.name"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item  label="技能数量" prop="skill_num">
                <el-select
                    v-model="form.skill_num"
                    filterable
                    clearable
                    placeholder="选择技能数量"
                    class="skill_num_c"
                >
                    <el-option
                        v-for="item in AllSkillNum"
                        :key="item.name"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="技能名称" prop="skill">
                <el-input
                    v-model="form.skill"
                    placeholder="技能名称搜索"
                    @keyup.enter.native="search()"
                    clearable
                    class="skill_c"
                ></el-input>
            </el-form-item>
            <el-form-item  label="机密" prop="secret_type">
                <el-select
                    v-model="form.secret_type"
                    filterable
                    clearable
                    placeholder="机密任务类型"
                    class="secret_type_c"
                >
                    <el-option
                        v-for="item in AllSecretType"
                        :key="item.name"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="search">搜索</el-button>
                <el-button type="warning" plain @click="resetbtn('searchform')"
                    >重置</el-button
                >
            </el-form-item>
            <el-button
                v-if="$_has(AddAuth)"
                type="success"
                plain
                round
                @click="addTeam"
                class="temp_add"
                size="medium"
                >添加角色</el-button
            >
        </el-form>
    </el-card>
</template>

<script>
export default {
    name: 'CardRoleSearchBarComponent',
    data() {
        return {
            fdialogFormVisible: false,
            form: {
                name: '',
                gender: '',
                group:'',
                hide:'',
                skill_num: '',
                skill: '',
                secret_type: '',
                page: 1
            }
        }
    },
    computed: {},
    methods: {
        search() {
            this.$emit('search', this.form)
        },
        resetbtn(f) {
            this.$refs[f].resetFields()
        },
        addTeam() {
            this.$emit('addTeam')
        },
    },
    filters: {},
    props: {
        AllGender: {
            type: Array,
            default() {
                return []
            },
        },
        AllGroup: {
            type: Array,
            default() {
                return []
            },
        },
        AllHide: {
            type: Array,
            default() {
                return []
            },
        },
        AllSecretType: {
            type: Array,
            default() {
                return []
            },
        },
        AllSkillNum: {
            type: Array,
            default() {
                return []
            },
        },
        AddAuth: String
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {},
}
</script>

<style lang='less' scoped>
.box-card .el-form-item {
    margin-bottom: 5px;
}
.temp_add {
    float: right;
    margin-bottom: 10px;
}
.name_c {
    width:120px
}
.gender_c {
    width:70px
}
.group_c {
    width:120px
}
.hide_c {
    width:120px
}
.skill_c,.skill_num_c {
    width:120px
}
.secret_type_c {
    width:150px
}
</style>