<template>
    <div>
        <el-dialog
            :visible.sync="dialogEggVisible"
            :append-to-body="true"
            width="750px"
            :center="true"
            top="100px"
            :close-on-click-modal="false"
            :before-close="() => closeEggDialog()"
        >
            <el-form
                :model="EggForm"
                :rules="rules"
                ref="EggForm"
                label-width="100px"
                label-position="top"
            >
                <div style="font-family: HarmonyOS_Sans_Black;font-size: 25px;color: #9F35FF;margin-bottom: 5px;">？？？：{{ slogan }}</div>
                <el-form-item prop="reply_gender">
                    <template v-slot:label><span><span style="color: #AE0000;">【日之封印】</span>ta是男性还是女性？</span></template>
                    <el-radio-group v-model="EggForm.reply_gender">
                        <el-radio :label="1"><span>男</span></el-radio>
                        <el-radio :label="2"><span>女</span></el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item prop="reply_hide">
                    <template v-slot:label><span><span style="color: #0080FF;">【月之封印】</span>ta是公开角色还是隐藏角色？</span></template>
                    <el-radio-group v-model="EggForm.reply_hide">
                        <el-radio :label="1"><span>公开</span></el-radio>
                        <el-radio :label="0"><span>隐藏</span></el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item prop="reply_num">
                    <template v-slot:label><span><span style="color: #D9B300;">【星之封印】</span>ta有几个技能？</span></template>
                    <el-radio-group v-model="EggForm.reply_num">
                        <el-radio :label="1"><span>1个</span></el-radio>
                        <el-radio :label="2"><span>2个</span></el-radio>
                        <el-radio :label="3"><span>3个</span></el-radio>
                        <el-radio :label="4"><span>4个</span></el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div style="color: #FF0000;">【尝试破除封印需消耗1荣誉积分，成功破除封印的玩家，即可解救该角色，并获得ta的感恩回报。】</div>
            <div slot="footer">
                <el-button type="danger" plain @click="submit('EggForm')" >破！！！</el-button>
                <el-button plain @click="closeEggDialog()">保命要紧</el-button>
            </div>
            <card-role-prize-draw-component
                :id="id"
                :CardRoledialogVisible="CardRoledialogVisible"
                @closeDialog="closeDialog"
            ></card-role-prize-draw-component>
        </el-dialog>
    </div>
</template>

<script>
import { CardRoleEggRequest } from '@/network/card/CardRole.js'
import CardRolePrizeDrawComponent from '@/components/card/CardRole/CardRolePrizeDrawComponent'

export default {
    name:'',
    data(){
        return {
            EggForm: {
                reply_gender:'',
                reply_hide: '',
                reply_num:''
            },
            rules: {
                reply_gender: [
                    {
                        required: true,
                        message: '请选择性别',
                        trigger: 'blur',
                    },
                ],
                reply_hide: [
                    {
                        required: true,
                        message: '请选择属性',
                        trigger: 'blur',
                    }
                ],
                reply_num: [
                    {
                        required: true,
                        message: '请选择技能个数',
                        trigger: 'blur',
                    }
                ]
            },
            CardRoledialogVisible: false
        }
    },
    computed:{},
    methods:{
        closeEggDialog() {
            this.EggForm = {
                reply_gender:'',
                reply_hide: '',
                reply_num:''
            }
            this.$emit('ExitEggDialog')
        },
        submit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }

                this.EggForm.id = this.id
                CardRoleEggRequest(this.EggForm)
                    .then((s) => {
                        if (s.status === 0) {
                            this.$message.success('恭喜你，成功破除封印，获得丰厚的回报奖励！')
                            this.CardRoledialogVisible = true
                            this.closeEggDialog()
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                this.closeEggDialog()
            })
        },
        closeDialog() {
            this.CardRoledialogVisible = false
            this.closeEggDialog()
            this.$emit('refresh')
        }
    },
    filters:{},
    props:{
        dialogEggVisible: {
            type: Boolean,
            default() {
                return false
            }
        },
        id: {
            type: Number,
            default() {
                return 0
            }
        },
        slogan: {
            type: String,
            default() {
                return ''
            }
        }
    },
    created(){},
    beforeDestroy(){},
    mounted(){},
    updated(){},
    components:{
        CardRolePrizeDrawComponent
    },
    watch:{}
}
</script>

<style lang='less' scoped>
span {
    font-size: 20px;
}
</style>