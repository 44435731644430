<template>
    <div v-loading.fullscreen.lock="loading">
        <card-role-search-bar-component
            AddAuth="新增角色"
            :AllGender="AllGender"
            :AllGroup="AllGroup"
            :AllHide="AllHide"
            :AllSecretType="AllSecretType"
            :AllSkillNum="AllSkillNum"
            @search="get_card_role_index"
            @addTeam="addTeam"
        ></card-role-search-bar-component>
        <el-descriptions
            direction="vertical"
            :column="8"
            :labelStyle="{'text-align':'center','background-color':'#e8edf2','width':'12.5%'}"
            :contentStyle="{'text-align': 'center','background-color':'#e8edf2'}"
            :colon="false"
            border
        >
            <el-descriptions-item v-for="item in res_data" :key="item.name">
                <template slot="label">
                    <span></span>
                </template> 
                
                <transition name="el-zoom-in-center">
                    <img
                        style="width: 100%;border-radius: 10px;"
                        :src="item['picture'].length > 0 ? item['picture'][0]['url'] : ''"
                        @click="details_card_role(item)" 
                        v-show="transition_box"
                    />
                </transition>
                <div @click="details_card_role(item)">
                    <div v-if="item.gender === 1"><img :src="$store.state.baseURL+'icon/xingbienan.png'" height="10px"><span style="color: #2894FF;">{{ item.name }}</span></div>
                    <div v-else-if="item.gender === 2"><img :src="$store.state.baseURL+'icon/xingbienv.png'" height="10px"><span style="color: #FF60AF;">{{ item.name }}</span></div>
                    <div v-else><img :src="$store.state.baseURL+'icon/wenhao.png'" height="10px"><span style="color: #9F35FF;">{{ item.name }}</span></div>
                </div>
            </el-descriptions-item>
            <el-descriptions-item v-for="i in res_buchong" :key="i+'bc'">
                <template slot="label">
                    <span></span>
                </template>
                <div style="width: 100%"></div>
            </el-descriptions-item>
        </el-descriptions>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="total"
            layout="total, prev, pager, next, jumper"
            @search="get_card_role_index"
        ></common-page-component>
        <card-role-edit-component
            edit_auth="修改角色"
            del_auth="删除角色"
            :id="fid"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :card_role_details_data="fcard_role_details_data"
            :AllGender="AllGender"
            :AllGroup="AllGroup"
            :AllHide="AllHide"
            :AllSecretType="AllSecretType"
            @exitCardRoleDialog="dialogExit"
            @search="get_card_role_index"
            @show_edit="show_edit"
            @cancel_edit="cancel_edit"
            @details_row="details_card_role"
        ></card-role-edit-component>
        <card-role-egg-component
            :id="fid"
            :slogan="slogan"
            :dialogEggVisible="dialogEggVisible"
            @refresh="get_card_role_index"
            @ExitEggDialog="dialogEggVisible = false"
        ></card-role-egg-component>
        <!-- 语音 -->
        <audio v-if="$store.state.speech_url" :src="$store.state.speech_url" preload="auto" ref="role_audio">不支持播放</audio>
    </div>
</template>

<script>
import { CardRoleIndexRequest,CardRoleDetailsRequest } from '@/network/card/CardRole.js'

import CardRoleSearchBarComponent from '@/components/card/CardRole/CardRoleSearchBarComponent'
import CardRoleEditComponent from '@/components/card/CardRole/CardRoleEditComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import CardRoleEggComponent from '@/components/card/CardRole/CardRoleEggComponent'

export default {
    name:'',
    data(){
        return {
            transition_box: false,
            fid: 0,
            slogan: '',
            edit_type: 0,
            fdialogFormVisible: false,
            loading: true,
            total: 0,
            cond: {
                name: '',
                gender: '',
                group: '',
                hide: '',
                skill_num: '',
                skill: '',
                secret_type: '',
                page: 1,
                limit: 16
            },
            res_data: [],
            res_buchong: 0,
            fcard_role_details_data: {},
            dialogEggVisible: false,    //彩蛋
            AllGender:[
                {id:1,name:'男'},
                {id:2,name:'女'}
            ],
            AllGroup:[
                {id:0,name:'标准系列'},
                {id:1,name:'进阶系列'},
                {id:2,name:'血色密令'},
                {id:3,name:'绝密猎杀'},
                {id:4,name:'致命蔷薇'},
                {id:5,name:'谍影危机'},
                {id:6,name:'特殊系列'}
            ],
            AllHide:[
                {id:0,name:'隐藏角色'},
                {id:1,name:'公开角色'}
            ],
            AllSecretType: [
                {id:'无',name:'无'},
                {id:'情报收集',name:'情报收集'},
                {id:'手牌收集',name:'手牌收集'},
                {id:'一军一潜',name:'一军一潜'},
                {id:'亲手杀死',name:'亲手杀死'},
                {id:'自己死亡',name:'自己死亡'},
                {id:'条件死人',name:'条件死人'},
                {id:'阵营屠边',name:'阵营屠边'},
                {id:'协助共赢',name:'协助共赢'},
                {id:'特殊任务',name:'特殊任务'},
                {id:'截取胜利',name:'截取胜利'}
            ],
            AllSkillNum: [
                {id:1,name:'单技能'},
                {id:2,name:'双技能'},
                {id:3,name:'三技能'},
                {id:4,name:'四技能'}
            ]
        }
    },
    computed:{},
    methods:{
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
        },
        show_edit() {
            this.edit_type = 2
        },
        cancel_edit() {
            this.edit_type = 0
        },
        get_card_role_index(param = {}) {
            //清除语音
            this.$store.commit('edit_speech_url', [])

            this.loading = true
            this.transition_box = false
            this.cond.name = param.name ?? this.cond.name
            this.cond.gender = param.gender ?? this.cond.gender
            this.cond.group = param.group ?? this.cond.group
            this.cond.hide = param.hide ?? this.cond.hide
            this.cond.skill_num = param.skill_num ?? this.cond.skill_num
            this.cond.skill = param.skill ?? this.cond.skill
            this.cond.secret_type = param.secret_type ?? this.cond.secret_type
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            CardRoleIndexRequest(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.res_data = s.result.data
                        this.total = s.result.total
                        const yushu = this.res_data.length % 8 === 0 ? 8 : this.res_data.length % 8
                        this.res_buchong = 8 - yushu
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
                .finally(() => this.transition_box = true)
        },
        details_card_role(item) {
            this.fid = item.id
            this.slogan = item.slogan
            CardRoleDetailsRequest(item.id)
                .then((s) => {
                    if(s.status === 0) {
                        this.fdialogFormVisible = true
                        this.fcard_role_details_data = s.result

                        //更新语音
                        this.$store.commit('edit_speech_url', this.fcard_role_details_data.show_speech)
                    } else if(s.status === -1) {
                        this.dialogEggVisible = true
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
            this.edit_type = 0

            //更新语音
            this.$store.commit('edit_speech_url', this.fcard_role_details_data.lost_speech)
        }
    },
    filters:{},
    props:{},
    created(){
        this.get_card_role_index()
    },
    beforeDestroy(){},
    mounted(){},
    updated(){
        if(this.$store.state.speech_url) {
            this.$refs.role_audio.play()
        }
    },
    components:{
        CardRoleSearchBarComponent,
        CardRoleEditComponent,
        CommonPageComponent,
        CardRoleEggComponent
    },
    watch:{},
}
</script>

<style lang='less' scoped></style>